.myWrapper {
  min-height: 0%;
  max-height: 5%;
  min-width: 100%;
  max-width: 200%;
  background: #383838 !important;
}

.myTabBar {
  background: #383838 !important;
}

.rdTabAddButton {
  display: none;
}

.rdTabContainer {
  background: yellow !important;
}

.rdTabWrapper,
.myTabBar {
  background: #262626;
  padding: 0px 0px 0px 0px !important;
  position: relative;
  z-index: 0;
}

.rdTab.myTab,
.rdTabBefore,
.rdTabAfter {
  max-width: 80px;
  border: 0px !important;
  border-bottom: 0px !important;
  outline: 0px !important;
  cursor: pointer !important;
  transform: none !important;
  background: #111111;
  box-shadow: none !important;
  color: rgba(255, 255, 255, 0.267);
  z-index: 0;
}

p.rdTabTitle.myTabTitle {
  color: white !important;
  font-size: 13px !important;
  font-family: "Lucida Grande", "Segoe UI", Ubuntu, Cantarell, sans-serif !important;
}

.myTabBar {
  min-width: 0vw !important;
  max-width: calc(100vw - 305px) !important;
  z-index: 0;
}

.myTabTitle,
.rdTab {
  cursor: pointer !important;
}
